/* import the necessary Bootstrap files */

// overwrite theme variables
$primary: #61ade0;
// $warning: #f8991d;
// $info: #3FA592;

$navbar-height: 7vh;
$footer-height: 23vh;

.theme-navbar {
    min-height: $navbar-height;
    background-color: #b0d5ee;
    color: #fff;

    .nav-link {
        color: #fff;
    }

    .nav-link:hover {
        color: #fff;
        text-decoration: underline;
        background-color: #7c213b;
        border-radius: 2px;
    }
}

#wrapper {
    min-height: 99 - $navbar-height - $footer-height;
}

.theme-footer {
    min-height: $footer-height;
    background-color: #7c213b;
    color: #fff;

    a {
        color: #fff;
    }

    a:hover {
        color: #fff
    }
}

.brand-logo {
    max-height: 90px;
    width: auto;
}

/* finally, import Bootstrap */
@import "~bootstrap/scss/bootstrap.scss"