.options_parent {
    position: relative;
}

.options_box {
  min-height: 4rem;
  position: absolute;
  z-index: 10;
}

input[type="radio"] {
  cursor: pointer;
}

input[type="radio"] + label {
    cursor: pointer;
  }
